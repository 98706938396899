import lodashGet from "lodash/get";
import {
  PROMO_CODE_FAIL,
  PROMO_CODE_REQUEST,
  PROMO_CODE_SUCCESS,
  APPLY_PROMO_CODE_FAIL,
  APPLY_PROMO_CODE_REQUEST,
  APPLY_PROMO_CODE_SUCCESS
} from "../constants/promoCodeConstants";

export const promoCodeReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case PROMO_CODE_REQUEST:
      return { loading: true };
    case PROMO_CODE_SUCCESS:
      return { loading: false, promoCode: action.payload.data };

    case PROMO_CODE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const applyPromoCodeReducer = (applyPromoCode = [], action) => {
  if (action.type === APPLY_PROMO_CODE_SUCCESS) {
    return action.payload;
  }
  return applyPromoCode;
}

