import React from "react";
import orders from "../../api/orders";
import { get } from "lodash";
import store from "../../store";
import { useAlert } from "react-alert";
import DiscountedProductsModal from "./DiscountedProductsModal";
import DiscountedAddonModal from "./DiscountedAddonModal";

const Coupon = ({
  coupon,
  customerInfoRender,
  employeeIdFromStore,
  branchIdFromStore,
  subTotalAmount,
  discount,
  setDiscount,
  setIsDiscountOpen,
  setIsCheckoutOpen,
  setProductCouponList,
  discountedProductModal,
  setDiscountedProductModal,
  productsCouponList,
  setAddonCouponList,
  AddonCouponList,
  discountedAddonsProductModal,
  setDiscountedAddonsProductModal,

}) => {
  const alert = useAlert();
  //console.log(customerInfoRender, 'customerInfoRender')

  const expiredHandler = () =>{
    alert.error(`This coupon can only be used ${coupon.max_use_value} times for this User.`)
  }


  const applyHandler = () => {
    if (coupon && coupon.discount_type === "product") {
      setDiscountedProductModal(true);
    }
    if (coupon && coupon.discount_type === "addon") {
      setDiscountedAddonsProductModal(true);
    }
    let data = {
      branch_id: branchIdFromStore,
      all_coupons_id: coupon.all_coupons_id,
      discount_type: coupon.discount_type,
      customer_no: customerInfoRender.mobile_no || null,
      customer_id: customerInfoRender.customer_id || customerInfoRender[0].customer_id,
      employee_id: employeeIdFromStore,
      price: subTotalAmount,
      bday: sessionStorage.getItem("bday"),
      payment_method: coupon.payment_method,
    };
    const token = get(store.getState(), "userLogin.userInfo.token");
    // amt === '' ? alert.error('Please enter a Amount') :
    orders
      .post(`/apply_coupon`, data, {
        headers: {
          token: token,
        },
      })
      .then((res) => {
        if (res.data.status === "Success") {
          alert.success("Coupon Applied Successfully");
          setDiscount(res.data.all_coupon);
          setIsDiscountOpen(false);
          //setIsCheckoutOpen(true);
          //dispatch(setPromoCode(res.data.data));
          // dispatch(applyPromoCode(res.data.data))
          //setApply(true)
        } else alert.error(res.data.msg);

        if (
          res.data.status === "Success" &&
          coupon.discount_type === "product"
        ) {
          setProductCouponList(res.data.allproducts);
          setAddonCouponList([]);
        } else if (
          res.data.status === "Success" &&
          coupon.discount_type === "addon"
        ) {
          //  console.log({ AddonCouponList, productsCouponList }, "diff");
          // setIsDiscountOpen(false);
          // setIsCheckoutOpen(false);

          setProductCouponList([]);
          setAddonCouponList(res.data.allproducts);
        }
      })
      .catch(() => {
        alert.error("error occured2");
      });
  };
  return (
    <div className={`w-full flex justify-between items-center bg-primaryColor/25 shadow p-2 mb-2 rounded-[10px] gap-2 ${(coupon.max_use_value != 0 && coupon.max_used_value >= coupon.max_use_value) ? 'filter grayscale' : ''}`}>
      <DiscountedProductsModal
        discountedProductModal={discountedProductModal}
        setDiscountedProductModal={setDiscountedProductModal}
        productsCouponList={productsCouponList}
        setIsCheckoutOpen={setIsCheckoutOpen}
        setIsDiscountOpen={setIsDiscountOpen}
        setDiscount={setDiscount}
      />
      <DiscountedAddonModal
        discountedAddonsProductModal={discountedAddonsProductModal}
        setDiscountedAddonsProductModal={setDiscountedAddonsProductModal}
        AddonCouponList={AddonCouponList}
        setIsCheckoutOpen={setIsCheckoutOpen}
        setIsDiscountOpen={setIsDiscountOpen}
        setDiscount={setDiscount}
      />
      <div className="w-[70%]">
        <h6 className="font-bold uppercase text-primaryColor">
          {coupon.code} -{" "}
          {coupon.discount_type === "flat"
            ? `₹${coupon.discount_value}`
            : coupon.discount_type === "percentage"
              ? `${coupon.discount_value}%`
              : ""}{" "}
        </h6>
        <h6 className="font-bold Capitalize text-primaryColor text-sm">
          {coupon.title}
        </h6>
        {!(coupon.max_used_value >= coupon.max_use_value) && <h3 className="text-[12px]">Uses left: {coupon.max_use_value - coupon.max_used_value}</h3>}
        {(coupon.max_use_value == 0) && <h3 className="text-[12px]">Uses left: Unlimited</h3>}
      </div>

      {
        <>
          {/* <button
                    className="bg-primaryColor/50 text-white uppercase font-bold text-sm px-2 py-1 shadow-sm rounded-[10px] hover:bg-primaryColor/70"
                    onClick={() => {
                        setDiscount(coupon);
                        setIsDiscountOpen(false);
                        setIsCheckoutOpen(true);
                    }}
                >
                    Apply
                </button> */}
          <button
            className="bg-primaryColor/50 text-white uppercase font-bold text-sm px-2 py-1 shadow-sm rounded-[10px] hover:bg-primaryColor/70"
            onClick={(coupon.max_use_value != 0 && coupon.max_used_value >= coupon.max_use_value) ? expiredHandler : applyHandler}
          >
            {(coupon.max_use_value != 0 && coupon.max_used_value >= coupon.max_use_value) ? "Expired" : "Apply"}
          </button>
        </>
      }
    </div>
  );
};

export default Coupon;


