import React from "react";
import Moment from "react-moment";
import "moment-timezone";
import orders from "../../api/orders";
import { useSelector } from "react-redux";
import { useState } from "react";
const OrderList = ({ orderid, price, order, onOrderSelect }) => {
  const state = useSelector(state => state);
  const [name, setName] = useState("");
  // orders.get(`/customer_search?mobile_no=${order?.customer_no}`, {
  //   headers: {
  //     token: state.userLogin.userInfo.token
  //   }
  // }).then(response => {
  //   if (response.data.data !== undefined && response.data.data[0] !== undefined) {
  //     setName(`${response.data.data[0].first_name} ${response.data.data[0].last_name}`);
  //   }
  // })
  const status = order.status;
  let borderColor;
  if (status === "Hold") {
    borderColor = " flex flex-col border-l-orange-600";
  } else if (status === "Preparing") {
    borderColor = " flex flex-col border-l-sky-500";
  } else if (status === "Completed") {
    borderColor = "flex flex-col border-l-green-500";
  } else if (status === "Cancelled") {
    borderColor = " flex flex-col border-l-red-500";
  }
  const onClick = () => {
    onOrderSelect(order);
  };
  return (
    <div
      className={` ${borderColor} relative  min-w-0 rounded-[10px] break-words bg-white border-closedColor cursor-pointer  p-2 m-2 border-l-4 `}
      onClick={onClick}
    >
      {/* <div className="flex justify-between items-center ">
        <div className="">
          <h6 className="font-bold mb-0">{orderid}</h6>
          <div className="flex justify-between">
          <p className="font-bold mb-0 text-sm text-gray-500">
            {order?.customer_no || "Guest"}
          </p>
            <p className="font-bold mb-0 text-sm text-gray-500">{name}</p>
          </div>
        </div>
        <div className=" flex items-end justify-center flex-col">
          <h6 className="font-bold mb-0">
            ₹{status === "Cancelled" ? Math.round(order?.sub_total * 100) / 100 : Math.round(price * 100) / 100}
          </h6>
          <p className="font-bold mb-0 text-sm text-gray-500 ">
            <Moment format="MMM D, YYYY hh:mm a">{order.createdAt}</Moment>
          </p>
        </div>
      </div> */}
      <div className="flex justify-between font-bold mb-0">
        <h6>{orderid}</h6>
        <h6>₹{status === "Cancelled" ? Math.round(order?.sub_total * 100) / 100 : Math.round(price * 100) / 100}</h6>
      </div>
      <div className="flex justify-between font-bold mb-0 text-sm text-gray-500">
        <p>{order?.customer_name || ""}</p>
        <p>{order?.customer_no}</p>
        <p>
          <Moment tz="Asia/Kolkata" format="MMM D, YYYY hh:mm a">{order.createdAt}</Moment>
        </p>
      </div>
    </div>
  );
};

export default OrderList;
