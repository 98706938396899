import React from "react";
import OnHoldItemCard from "./OnHoldItemCard";

const OnHoldItemsCard = ({ order_items }) => {
  return (
    <>
      {order_items?.map((order_item) => {
        return (
          <OnHoldItemCard
            order_item={order_item}
            key={order_item.order_items_id}
          />
        );
      })}
    </>
  );
};

export default OnHoldItemsCard;
