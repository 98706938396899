import React, { useState, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useAlert } from "react-alert";
import { useEffect } from "react";

const WalletCard = ({ isWCOpen, setIsWCOpen, goBack, setWalletAmt, price }) => {
    const alert = useAlert();

    const [amt, setAmt] = useState('');
    const adWCharges = (amount) => {
        if (amount >= 0) setAmt(amount);
    }

    const closeModal = () => {
        setAmt('');
        setIsWCOpen(false);
        goBack(true);
    }

    const inputRef = useRef(null);
    useEffect(() => {
        if (isWCOpen && inputRef.current !== null) inputRef.current.focus();
    }, [isWCOpen, inputRef.current])
    return (
        <>
            <Transition appear show={isWCOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 "
                    style={{ zIndex: "67" }}
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black/20 backdrop-blur-[3px] w-full md:w-full" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                className="inline-block w-full max-w-[400px] p-4 my-8 overflow-hidden text-center align-middle transition-all transform bg-white shadow-xl rounded-2xl"
                            >
                                <Dialog.Title>
                                    <div className="text-2xl font-bold uppercase">Enter Wallet Amount</div>
                                </Dialog.Title>
                                <div className="w-full mt-3">
                                    <input type="number" className="outline outline-2 p-2 rounded-[10px]" placeholder="Wallet Amount" onChange={(e) => adWCharges(e.target.value)} value={amt} ref={inputRef} />
                                    <div className="flex justify-center items-center gap-4 mt-4" >
                                        <button
                                            type="button"
                                            className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-slate-500 bg-slate-100 border border-transparent rounded-[10px] hover:bg-slate-200"
                                            onClick={closeModal}
                                        >
                                            Close
                                        </button>
                                        <button className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-white bg-primaryColor border border-transparent rounded-[10px] hover:bg-primaryDarkColor" onClick={() => {
                                            // setDeliveryCharges(amt);
                                            if (amt > price) {
                                                alert.error("Wallet Amount can't be more than Total Price");
                                            }
                                            else {
                                                setWalletAmt(Number(amt));
                                                closeModal();
                                            }
                                        }}>Proceed</button>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default WalletCard;
