import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  SELECT_BRANCH_FAIL,
  SELECT_BRANCH_REQUEST,
  SELECT_BRANCH_SUCCESS,
  BRANCH_VERIFY_FAIL,
  BRANCH_VERIFY_REQUEST,
  BRANCH_VERIFY_SUCCESS,
  USER_LOGOUT,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_REQUEST,
  NOTIFICATION_FAIL,
} from "../constants/userConstants";
import orders from "../api/orders";
import { get } from "lodash";
import store from "../store";

//ACTION TO GET DETAILS OF USER BASED ON THE TOKEN
export const detailsUser = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });

    //FETCHING THE USERINFO FROM THE USERLOGIN STATE PRESENT IN THE REDUX STORE TO LATER GET THE TOKEN FROM
    const {
      userLogin: { userInfo },
    } = getState();
    //GETTING THE TOKEN AND MAKING THE REQUEST
    const { data } = await orders.get(`/employee_info?token=${userInfo.token}`);
    //DISPATCHING THE FETCHED RESPONSE
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data?.data });
  } catch (error) {
    //DISPATCHING THE ERROR IF ANY
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// login action
export const login = (email, password) => async (dispatch) => {
  dispatch({ type: USER_LOGIN_REQUEST, payload: { email, password } });

  try {
    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await orders.post(
      `/emp_login_v2`,
      { email, password },
      config
    );
    // if success, dispatch success and set payload to data
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
    // save data to sessionStorage
    sessionStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    // if error, dispatch FAIL, set payload to error message
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// select branch action
export const selectBranch = (employee_id, branch_name, branch_id) => async (dispatch) => {
  dispatch({ type: SELECT_BRANCH_REQUEST, payload: { employee_id, branch_name, branch_id } });

  try {
    //GETTING THE TOKEN AND ADDING IT TO CONFIG
    const token = get(store.getState(), "userLogin.userInfo.token");
    const config = {
      headers: { "Content-Type": "application/json", token: token },
    };

    const { data } = await orders.post(
      `/emp_login_branch`,
      { employee_id, branch_name, branch_id },
      config
    );
    // if success, dispatch success and set payload to data
    dispatch({ type: SELECT_BRANCH_SUCCESS, payload: data });
    // save data to sessionStorage
    sessionStorage.setItem("userBranch", JSON.stringify(data));

  } catch (error) {
    // if error, dispatch FAIL, set payload to error message
    dispatch({
      type: SELECT_BRANCH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


// verify branch action
export const branchVerify = (OTP, branch_id, employee_id) => async (dispatch) => {
  dispatch({ type: BRANCH_VERIFY_REQUEST, payload: { OTP, branch_id, employee_id } });

  try {
    //GETTING THE TOKEN AND ADDING IT TO CONFIG
    const token = get(store.getState(), "userLogin.userInfo.token");
    const config = {
      headers: { "Content-Type": "application/json", token: token },
    };

    const { data } = await orders.post(
      `/verify_branch_otp`,
      { OTP, branch_id, employee_id },
      config
    );
    // if success, dispatch success and set payload to data
    dispatch({ type: BRANCH_VERIFY_SUCCESS, payload: data });
    // save data to sessionStorage
    sessionStorage.setItem("verifyBranch", JSON.stringify(data));

  } catch (error) {
    // if error, dispatch FAIL, set payload to error message
    dispatch({
      type: BRANCH_VERIFY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// fetch notification
export const fetch_notifications = (branch_id, employee_id) => async (dispatch) => {
  dispatch({ type: NOTIFICATION_REQUEST, payload: { branch_id, employee_id } });

  try {
    //GETTING THE TOKEN AND ADDING IT TO CONFIG
    const token = get(store.getState(), "userLogin.userInfo.token");
    const config = {
      headers: { "Content-Type": "application/json", token: token },
    };

    const { data } = await orders.post(
      `/fetch_notifications`,
      {
        "employee_id": employee_id,
        "branch_id": branch_id
      },
      config
    );
    // if success, dispatch success and set payload to data
    dispatch({ type: NOTIFICATION_SUCCESS, payload: data });
    // save data to sessionStorage

  } catch (error) {
    // if error, dispatch FAIL, set payload to error message
    dispatch({
      type: NOTIFICATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// logout action
export const logout = () => (dispatch) => {
  //remove userInfo and cartItems from sessionStorage upon user signout
  sessionStorage.removeItem("userInfo");
  sessionStorage.removeItem("userBranch");
  sessionStorage.removeItem("verifyBranch");
  sessionStorage.removeItem("cashier_popup");
  sessionStorage.removeItem("bday");
  //sessionStorage.removeItem("employee_cashier_id");
  sessionStorage.removeItem("customerInfo");
  sessionStorage.removeItem("popularItems");
  sessionStorage.removeItem("cartItems");
  //sessionStorage.removeItem("userdetails");

  //sessionStorage.removeItem('cartItems');
  dispatch({ type: USER_LOGOUT });
};
