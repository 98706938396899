import { useCallback, useRef, useState } from "react";
import moment from "moment";
import 'moment-timezone';

import { isString } from "lodash";
// import { useAppContext } from "../context/AppContext";

const useTimer = () => {
  const intervalRef = useRef();
  const [duration, setDuration] = useState(moment.duration({ seconds: 0 }));
  // const {setStartFunction}=useAppContext();

  const stop = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  }, []);

  const reset = useCallback(() => {
    stop();
    setDuration(moment.duration(0));
  }, [stop]);

  const update = useCallback(() => {
    setDuration((duration) => {
      return duration.clone().add({ seconds: 1 });
    });
  }, []);

  const start = useCallback(
    (newDuration) => {
      stop();

      if (isString(newDuration)) {
        let [hours, minutes, seconds] = newDuration.split(":");
        hours = +hours;
        minutes = +minutes;
        seconds = +seconds;
        if (isNaN(hours)) hours = 0;
        if (isNaN(minutes)) minutes = 0;
        if (isNaN(seconds)) seconds = 0;
        setDuration(moment.duration({ seconds, minutes, hours }));
      }
      intervalRef.current = setInterval(() => {
        update();
      }, 1000);
    },
    [update, stop]
  );

  const addLeadingZeros = useCallback((number) => {
    return number <= 9 ? `0${number}` : number;
  }, []);

  const formatDuration = useCallback(
    (duration) => {
      const seconds = duration.seconds();
      const minutes = duration.minutes();
      const hours = duration.hours();

      return `${addLeadingZeros(hours)}:${addLeadingZeros(
        minutes
      )}:${addLeadingZeros(seconds)}`;
    },
    [addLeadingZeros]
  );

  return { start, stop, reset, duration: formatDuration(duration) };
};

export default useTimer;
