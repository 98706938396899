import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { DiscountedProduct } from "../product/Product";

const DiscountedProductsModal = ({
  discountedProductModal,
  setDiscountedProductModal,
  setIsCheckoutOpen,
  productsCouponList,
  setIsDiscountOpen,
  setDiscount,
}) => {
  //FUNCTION TO CLOSE THE MODAL
  let completeButtonRef = useRef(null);

  function closeModal() {
    // setDiscount(null)
    setDiscountedProductModal(!discountedProductModal);
  }

  const modifiedArray = productsCouponList.map((product, i) => {
    return Object.assign({ product_add_from: "coupon" }, product);
  });


  return (
    <>
      <Transition appear show={discountedProductModal} as={Fragment}>
        <Dialog
          //initialFocus={completeButtonRef}
          as="div"
          className="fixed inset-0 "
          style={{ zIndex: "80" }}
          onClose={closeModal}

        >
          <div
            //ref={completeButtonRef}
            className="min-h-screen px-4 text-center"
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-black/20 backdrop-blur-[3px] w-full " />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-screen-lg p-3  overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title className="flex">
                  <div className="uppercase font-bold text-xl text-center text-gray-800">
                    Discounted Products List
                  </div>

                  <button
                    type="button"
                    className=" ml-auto text-center justify-right px-4 py-2 text-sm font-bold uppercase text-slate-500 bg-slate-100 border border-transparent rounded-[10px] hover:bg-slate-200 w-1/3 "
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </Dialog.Title>

                {/*MAPPING THE COUPON DATA THAT WAS RECEIVED IN THE DISCOUNT MODAL*/}
                <div className="w-full flex justify-left flex-wrap my-5 p-1 h-[400px] gap-3 overflow-auto">
                  {modifiedArray.map((product, i) => {
                    return (
                      <DiscountedProduct
                        key={i}
                        discountedProduct={product}
                        setDiscountedProductModal={setDiscountedProductModal}
                      />
                    );
                  })}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default DiscountedProductsModal;
