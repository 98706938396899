import {
  ALL_CATEGORY_REQUEST,
  ALL_CATEGORY_SUCCESS,
  ALL_CATEGORY_FAIL,
  CLEAR_ERRORS,
} from "../constants/categoryConstants";
import { get } from "lodash";
import store from "../store";
import orders from "../api/orders";
//ACTION TO GET BRANCHWISE CATEGORY
export const getCategory = (branch) => async (dispatch) => {
  //FETCHING THE CATEGORIES USING THE BRANCH
  // console.log(branch)
  try {
    //FETCHING THE TOKEN AND ADDING IT TO CONFIG
    const token = get(store.getState(), "userLogin.userInfo.token");
    const config = { headers: { token: token } };
    dispatch({ type: ALL_CATEGORY_REQUEST });

    const data = await orders.get(
      `/get_category_by_branch?branch=${branch}`,
      config
    );
    //REDIRECTING TO LOG-IN PAGE IF THE TOKEN CHANGES
  

    if (data.data.data === "Token does not match") {
      sessionStorage.clear();
      window.location.href = "/login";
    }

    //DISPATCHING THE ACQUIRED DATA
    dispatch({ type: ALL_CATEGORY_SUCCESS, payload: data?.data });
  } catch (error) {
    //DISPATCHING THE ERROR (IF ANY)
    dispatch({
      type: ALL_CATEGORY_FAIL,
      payload: error.response?.data.message,
    });
  }
};

//ACTION TO CLEAR ERRORS OCCURED WHILE FETCHING CATEGORY

export const clearCategoryErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
