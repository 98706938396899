import {
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  REMOVE_ALL_CART_ITEM,
} from "../constants/cartConstants";
import store from "../store";
import { get } from "lodash";
import orders from "../api/orders";

//ACTION TO ADD ITEMS TO YOUR CART

export const addItemsToCart =
  (
    sku,
    id,
    qty,
    addOn,
    addOnPrice,
    food_type,
    prepare_time,
    product_type,
    note,
    product,
    edit,
    prevsku,
    product_add_from,
    newproduct
  ) =>
  async (dispatch, getState) => {
   // console.log(newproduct, "productaddfromapifull");
    //console.log(product_add_from, "productaddfromapi");
    //FETCHING THE TOKEN AND ADDING IT TO CONFIG
    const token = get(store.getState(), "userLogin.userInfo.token");
    const config = { headers: { token: token } };

    //GETTING PRODUCT INFO FROM THE API

    if (product_add_from === "coupon") {
      var newpl = {
        sku,
        id: newproduct.products[0].product_id,
        name: newproduct.product_name,
        price: newproduct.price,
        image: newproduct.card_img,
        stock: newproduct.products[0].items_available,
        add_ons: addOn,
        add_on_price: addOnPrice,
        qty,
        food_type,
        prepare_time,
        product_type,
        note,
        product,
        edit,
        prevsku,
      };
      dispatch({
        type: ADD_TO_CART,
        payload: newpl,
      });
    } else {
      const { data } = await orders.get(
        `/get_single_product?product=${id}`,
        config
      );

      //ADDING ITEMS TO THE CART

      let pl = {
        sku,
        id: data.data.product_id,
        name: data.data.product_list.product_name,
        price: data.data.product_list.price + data.data.price,
        image: data.data.product_list.card_img,
        stock: data.data.items_available,
        add_ons: addOn,
        add_on_price: addOnPrice,
        qty,
        food_type,
        prepare_time,
        product_type,
        note,
        product,
        edit,
        prevsku,
      };

      dispatch({
        type: ADD_TO_CART,
        payload: pl,
      });
    }

    //SAVING THE CART ITEMS IN sessionStorage

    sessionStorage.setItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  };
//ACTION TO REMOVE AN ITEM FROM YOUR CART

export const removeItemsFromCart = (sku) => async (dispatch, getState) => {
  //REMOVING THE ITEM USING THE UNIQUE SKU
  dispatch({
    type: REMOVE_CART_ITEM,
    payload: sku,
  });

  //UPDATING THE LOCAL STORAGE AFTER REMOVING THE ITEM

  sessionStorage.setItem(
    "cartItems",
    JSON.stringify(getState().cart.cartItems)
  );
};

//ACTION TO REMOVE ALL ITEMS FROM YOUR CART INCLUDING THE CUSTOMER ADDED

export const removeAllItemsFromCart = () => async (dispatch, getState) => {
  //REMOVING ALL THE ITEMS

  dispatch({
    type: REMOVE_ALL_CART_ITEM,
  });

  //RESETTING THE LOCAL STORAGE

  sessionStorage.removeItem("cartItems");
  sessionStorage.removeItem("customerInfo");
  sessionStorage.removeItem("popularItems");
  sessionStorage.removeItem("bday", "true");
};
export const removeAllItemsOnlyFromCart = () => async (dispatch, getState) => {
  //REMOVING ALL THE ITEMS

  dispatch({
    type: REMOVE_ALL_CART_ITEM,
  });

  //RESETTING THE LOCAL STORAGE

  sessionStorage.removeItem("cartItems");
};
