import React from 'react'

const Error = () => {

    
  const handleClick = () => {
    window.location.href = "/";
  };
  return (
    <div className="bg-primaryLightColor25 w-full h-[100vh] flex justify-center items-center ">
      <div className=" bg-[#fff] border-[rgba(0, 0, 0, 0.35) 0px 5px 15px]  w-[350px] rounded-[10px] p-5">
        <div className="h-40 w-40  bg-transparent flex justify-center  items-center m-auto ">
          <img src="404.svg" alt="svg" />
        </div>
        <div> 
        <h1 className="flex-col justify-centre text-primaryDarkColor uppercase text-bold flex justify-center items-center m-2 font-bold text-2xl ">page not found</h1>
        <span className="flex justify-center items-center text-mutedColor text-sm">the page you are looking for not available!</span>
        </div>

        
        <button onClick={handleClick} className="uppercase m-4 p-2 w-[280px] flex justify-center items-center rounded-[10px] bg-primaryLightColor25 text-primaryDarkColor font-bold">
          Return Home
        </button>
      </div>
    </div>
  )
}

export default Error