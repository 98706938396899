import React, { useEffect } from "react";
import "./cancelled.css";


const CancelledPage = () => {

  const handleClick = () => {
    window.location.href = "/";
  };
  
  useEffect(() => {
    setTimeout(() => {
      handleClick();
    }, 3000);
  }, []);
  
  return (
    <div className="bg-primaryLightColor25 w-full h-[100vh] flex justify-center items-center ">
      <div className="bg-white border-[rgba(0, 0, 0, 0.35) 0px 5px 15px]  w-[350px] rounded-[10px] p-5">
        <div className="success-animation">
          <svg
            className="checkmark"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>
        <div>
          <h1 className="flex-col justify-centre text-red-500 text-bold flex justify-center items-center m-2 font-bold text-3xl ">
            Order Cancelled
          </h1>
        </div>

        <a
          href="/"
          className="uppercase m-4 p-2 w-[280px] flex justify-center items-center rounded-[10px] bg-primaryLightColor25 text-primaryDarkColor font-bold"
        >
          Return Home
        </a>
      </div>
    </div>
  );
};

export default CancelledPage;
