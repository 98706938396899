import { ChevronRightIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import Moment from "react-moment";
import 'moment-timezone';

const LogoutReportList = (props) => {
    const { logoutReport, setEmployeeId, setBranchId, setLogIn, setLogOut } = props;

    function handleSetLogoutId() {
        setEmployeeId(logoutReport.employee_id);
        setBranchId(logoutReport.branch_id);
        setLogIn(logoutReport.log_in);
        setLogOut(logoutReport.log_out);
        //console.log(setEmployeeId);

    }

    return (
        <div className="p-2 bg-white rounded-[10px] m-2 border-l-4 border-primaryColor mb-1 flex justify-between items-center cursor-pointer" onClick={handleSetLogoutId}>
            <div>
                <h2 className="font-bold text-lg">Login Time: <Moment tz="Asia/Kolkata" className="text-gray-700" format="MMM D, YYYY hh:mm a">{logoutReport.log_in}</Moment></h2>
                <h2 className="font-bold text-lg">Logout Time: <Moment tz="Asia/Kolkata" className="text-gray-700" format="MMM D, YYYY hh:mm a">{logoutReport.log_out}</Moment></h2>
            </div>
            <div>
                <ChevronRightIcon className="w-6 h-6" />
            </div>
        </div>
    );
};

export default LogoutReportList;
