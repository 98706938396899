import {
    USER_DETAILS_FAIL,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    SELECT_BRANCH_FAIL,
    SELECT_BRANCH_REQUEST,
    SELECT_BRANCH_SUCCESS,
    BRANCH_VERIFY_FAIL,
    BRANCH_VERIFY_REQUEST,
    BRANCH_VERIFY_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    NOTIFICATION_REQUEST,
    NOTIFICATION_SUCCESS,
    NOTIFICATION_FAIL
} from '../constants/userConstants';


// User loginReducer 
export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return ({ loading: true });
        case USER_LOGIN_SUCCESS:
            return ({ loading: false, userInfo: action.payload });
        case USER_LOGIN_FAIL:
            return ({ loading: false, error: action.payload });
        case USER_LOGOUT:
            return {};

        default:
            return state;
    }
};

// User selectBranchReducer 
export const selectBranchReducer = (state = {}, action) => {
    switch (action.type) {
        case SELECT_BRANCH_REQUEST:
            return ({ loading: true });
        case SELECT_BRANCH_SUCCESS:
            return ({ loading: false, userBranch: action.payload });
        case SELECT_BRANCH_FAIL:
            return ({ loading: false, error: action.payload });
        default:
            return state;
    }
};

// User branchVerifyReducer 
export const branchVerifyReducer = (state = {}, action) => {
    switch (action.type) {
        case BRANCH_VERIFY_REQUEST:
            return ({ loading: true });
        case BRANCH_VERIFY_SUCCESS:
            return ({ loading: false, verifyBranch: action.payload });
        case BRANCH_VERIFY_FAIL:
            return ({ loading: false, error: action.payload });
        default:
            return state;
    }
};

//User Details reducer
export const userDetailsReducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case USER_DETAILS_REQUEST:
            return ({ loading: true });
        case USER_DETAILS_SUCCESS:
            return ({ loading: false, user: action.payload });
        case USER_DETAILS_FAIL:
            return ({ loading: false, error: action.payload });
        default:
            return state;
    }
};


//notification reducer
export const notificationReducer = (state = { loading: true }, action) => {
    switch (action.type) {
        case NOTIFICATION_REQUEST:
            return ({ loading: true });
        case NOTIFICATION_SUCCESS:
            return ({ loading: false, notification: action.payload });
        case NOTIFICATION_FAIL:
            return ({ loading: false, error: action.payload });
        default:
            return state;
    }
};
