import React from "react";
import Moment from "react-moment";
import 'moment-timezone';
import { useSelector } from "react-redux";
import { ReactComponent as NavIcon } from "../../assets/images/heebeeIcon.svg";

const SoldProductReport = ({ componentref, orderData, soldData, user_info }) => {
  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;

  const branchNameFromStore = useSelector(
    (state) => state.userDetails
  );
  // console.log(soldData)
  // console.log(data);
  return (
    <div className="p-[5px]" ref={componentref}>
      <div className="flex flex-col justify-center items-center mt-4">
        <div className="flex justify-center items-end relative">
          <NavIcon className="w-20 h-20" />
          <span className="absolute bottom-1 right-0">&#174;</span>
        </div>
        <h3 className="text-lg font-bold uppercase">HeeBee Coffee Private Limited</h3>
        <h3 className="text-md font-bold">
          <Moment tz="Asia/Kolkata" format="MMM D, YYYY">{date}</Moment>
        </h3>
        <h3 className="text-md font-bold uppercase border-2 border-black px-2 py-1/2">
          {user_info.branch_name}
        </h3>
      </div>
      <div className="flex justify-between items-center mb-10">
        <h3 className="text-md font-bold uppercase border-2 border-black px-2 py-1/2">
          {user_info.employee_name}
        </h3>
        
      </div>

      <div className="flex flex-col items-center  border-y-2 border-black border-dashed mb-4">
        <h3 className="text-md font-bold uppercase text-center border-b-2 border-black px-2 py-1 border-dashed w-full">
          Sold Product Report
        </h3>
        
        {!Array.isArray(soldData) ? <div>No Data Available</div> : <div></div>}
        
      </div>
      {Array.isArray(soldData) && soldData.map((item, i) => (
            <div className="border-b-2 border-black border-dashed mb-2">
                <div key={i} className="flex justify-between items-center w-full px-1 py-1/2">
                    <h2 className="text-[15px] font-bold capitalize">{item.product_name}</h2>
                    <h3 className="text-xs font-bold">
                    {item.quantity}
                    </h3>
                </div>
                {item.addon.map((addons, index)=>(
                    <div key={index} className=" items-center w-full px-1 py-1/2">
                        <h3 className="text-[14px] font-semibold capitalize">&emsp;{addons.addon_name}</h3>
                        {addons.addon_option.map((addon_option, j)=>(
                            <div key={j} className="flex justify-between items-center">
                                <h3 className="text-xs font-medium capitalize">&emsp;&emsp;-{addon_option.addon_option_title}</h3>
                                <h3 className="text-xs font-bold">
                                {addon_option.addon_option_quantity}
                                </h3>
                            </div>
                        ))}
                    </div>
                    
                ))}
            </div>
        ))}


      <div className="flex justify-between items-center mt-16 mb-5">
        <h3 className="text-md font-bold capitalize border-t-[2px] border-dashed border-black">
          Employee Sign.
        </h3>
        <h3 className="text-md font-bold capitalize border-t-[2px] border-dashed border-black">
          Manager Sign.
        </h3>
      </div>

    </div>
  );
};

export default SoldProductReport;
