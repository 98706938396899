import {
  CUSTOMER_ORDER_FAIL,
  CUSTOMER_ORDER_SUCCESS,
  CUSTOMER_ORDER_REQUEST,
} from "../constants/lastCustomerOrderConstants";
import store from "../store";
import { get } from "lodash";
import orders from "../api/orders";

//ACTION TO GET ORDERS OF THE LAST CUSTOMER
export const getLastCustomerOrderAction = (mobile_no) => async (dispatch) => {
  dispatch({ type: CUSTOMER_ORDER_REQUEST });

  try {
    //FETCHING THE TOKEN AND ADDING IT TO CONFIG
    const token = get(store.getState(), "userLogin.userInfo.token");
    const config = {
      headers: { "Content-Type": "application/json", token: token },
    };
    //MAKING THE REQUEST TO GET RECENT CUSTOMER ORDER DATA
    const { data } = await orders.get(
      `/fetch_last_5_customer_order?mobile=${mobile_no}`,
      config
    );
    //DISPATCHING THE FETCHED DATA
    dispatch({ type: CUSTOMER_ORDER_SUCCESS, payload: data.data });
  } catch (error) {
    //CATCHING THE ERROR AND DISPATCHING IT
    dispatch({
      type: CUSTOMER_ORDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
