import React, { useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  MinusIcon,
  PlusIcon,
  RefreshIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useAppContext } from "../../context/AppContext";
import { GrPowerReset } from "react-icons/gr";

import { addItemsToCart } from "../../actions/cartAction";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { useEffect } from "react";

const Modal = ({ isOpen, setIsOpen, product, edit, editItem }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const [qty, setQty] = useState(1);
  const [addComment, setAddComment] = useState("");
  const [addOn, setAddOn] = useState([]);
  const [addOnPrice, setAddOnPrice] = useState(0);
  const [skus, setSkus] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [alertAddOn, setAlertAddOn] = useState([]);
  // const [addOnSKU, setAddOnSKU] = useState({});

  const [productPrice, setProductPrice] = useState(
    product?.product_list?.price
  );
  const [sortedProduct, setSortedProduct] = useState(null);

  const { startFunction } = useAppContext();

  if (sortedProduct === null) {
    let product_addons = product?.product_list?.per_product_add_ons;
    product_addons = product_addons?.sort((a, b) =>
      a.order > b.order ? 1 : -1
    );
    let p = product;
    if (p !== null && !!p.product_list?.per_product_add_ons) {
      p.product_list.per_product_add_ons = product_addons;
      setSortedProduct(p);
    }
  }

  if (edit && firstRender) {
    setFirstRender(false);
    let addons = [];
    Object.entries(editItem.add_ons).forEach((entry) => {
      let newObject = {
        title: entry[0],
      };
      entry[1].forEach((x) => {
        Object.entries(x).forEach((y) => {
          if (y[0] === "value") newObject["desc"] = y[1];
          else newObject[y[0]] = y[1];
        });
        addons.push(newObject);
        newObject = {};
        newObject = {
          title: entry[0],
        };
      });
    });
    setAddOn(addons);
    setQty(editItem.qty);
    setAddComment(editItem.note);
  }

  function selectAddOn(addOnSelected, title, type) {
    let newAddOn = {
      title: title,
      desc: addOnSelected.title,
      price: addOnSelected.price,
      sku: addOnSelected.sku,
      order: addOnSelected.order,
    };
    let prevAddOns = addOn;
    let index = -1;
    prevAddOns.forEach((addOn, i) => {
      if (addOn.title === title && addOn.desc === newAddOn.desc) index = i;
    });
    if (index >= 0) {
      if (type === "checkbox") {
        prevAddOns.splice(index, 1);
        setAddOn([...prevAddOns]);
      }
    } else {
      if (type === "checkbox") {
        setAddOn((addOn) => [...addOn, newAddOn]);
      } else {
        prevAddOns.forEach((x, i) => {
          if (x.title === title) index = i;
        });
        if (index === -1) {
          setAddOn((addOn) => [...addOn, newAddOn]);
        } else {
          prevAddOns[index] = newAddOn;
          setAddOn([...prevAddOns]);
        }
      }
    }
    // const abc = JSON.parse(e.target.value);
    // addOn[e.target.name] = abc.title;

    // addOnPrice[e.target.name] = abc.price;
    // addOnSKU[e.target.name] = abc.sku;
    // setAddOn(addOn);
    // setAddOnPrice(addOnPrice);
    // setAddOnSKU(addOnSKU);
    // setProductPrice(
    //   (addOnPrice?.size || 0) +
    //     (addOnPrice?.flavour || 0) +
    //     (addOnPrice.milk || 0) +
    //     (product?.product_list?.price || 0)
    // );
  }

  useEffect(() => {
    setAddOnPrice(0);
    setSkus([]);
    addOn.forEach((addOn) => {
      setAddOnPrice((price) => price + addOn.price);
      setSkus((skus) => [
        ...skus,
        (addOn.desc + addOn.price).replaceAll(" ", ""),
      ]);
    });
  }, [addOn]);

  function closeModal() {
    // setAddOn({});
    // setAddOnSKU({});
    setIsOpen(false);
    setAddOnPrice(0);
    setAddOn([]);
    setAddComment("");
    setQty(1);
    setProductPrice(product?.product_list?.price);
    setAlertAddOn([]);
    setFirstRender(true);
  }

  const incQty = () => {
    if (product.items_available <= qty) return;
    setQty(qty + 1);
  };

  const decQty = () => {
    if (1 >= qty) return;
    setQty(qty - 1);
  };

  const food_type = product?.product_list?.food_type;
  const prepare_time = product?.product_list?.prepare_time;
  const product_type = product?.product_list?.product_type;
  const { cartItems } = useSelector((state) => state.cart);

  function compare(a, b) {
    if (a.sku < b.sku) {
      return -1;
    }
    if (a.sku > b.sku) {
      return 1;
    }
    return 0;
  }

  const newObject = (obj) => {
    return [
      obj.title,
      { value: obj.desc, price: obj.price, sku: obj.sku, order: obj.order },
    ];
  };

  const arrayToObj = (arr) => {
    let xAddOn = {};
    arr.forEach((el) => {
      let object = newObject(el);
      if (xAddOn.hasOwnProperty(object[0]) === false) {
        xAddOn[object[0]] = [object[1]];
      } else {
        let x = xAddOn[object[0]];
        x.push(object[1]);
      }
    });
    return xAddOn;
  };


  /* const check = (adds) => {
    let selectedaddOns = Object.keys(adds);
    let extra = [];
    product?.product_list?.per_product_add_ons.forEach((addon) =>
      extra.push(addon.add_on.title)
    );
    let return_value = true;
    for (let i = 0; i < extra.length; i++) {
      if (
        extra[i] === "Size" ||
        extra[i] === "Select Your Flavour" ||
        extra[i] === "Milk" ||
        extra[i] === "Flavours" ||
        extra[i] === "Festive coffee type" ||
        extra[i] === "Festive flavours"
      ) {
        if (selectedaddOns.includes(extra[i]) === false) {
          if (alertAddOn.includes(extra[i]) === false)
            setAlertAddOn((x) => [...x, extra[i]]);
          alert.error(`Please pick a choice from ${extra[i]}`);
          return_value = false;
        } else {
          let x = alertAddOn;
          let index = x.indexOf(extra[i]);
          if (index > -1) {
            x.splice(index, 1);
          }
          setAlertAddOn(x);
        }
      }
    }
    return return_value;
  }; */

  // function .. Rohit 11/01

  const validateOrder = () => {
    const requiredAddOns = product.product_list.per_product_add_ons.filter(item => item.required === true);
    if(requiredAddOns.length === 0) return true;
    if(requiredAddOns.length > addOn.length) return false;
    // requiredAddOns.forEach(req => console.log(req.add_on.title))
    // addOn.forEach(req => console.log(req.title))
    for(let i = 0; i< requiredAddOns.length; i++){
      let flag = true;
      for(let j = 0; j< addOn.length; j++){
        if(requiredAddOns[i].add_on.title === addOn[j].title) {
          flag = false;
          continue;
        }
      }
      if(flag)
        return false;
    }
    return true;
  }


  const addToCartHandler = () => {
    if(!validateOrder()) {
      alert.error('Must include required add-ons');
      return ;
    }
    const id = product.product_id;
    let sku = product?.product_list?.sku;
    let prevsku = edit ? editItem.sku : "";
    addOn.sort(compare);
    addOn.forEach((add) => {
      sku += "-" + add.sku;
    });
    const adds = arrayToObj(addOn);
    //let checkval = check(adds);
    //console.log(adds);
    // console.log(checkval);
    //if (checkval) {
    if (edit) {
      dispatch(
        addItemsToCart(
          sku,
          id,
          qty,
          adds,
          addOnPrice,
          food_type,
          prepare_time,
          product_type,
          addComment,
          product,
          edit,
          prevsku
        )
      );
      setIsOpen(false);
      alert.success("Quantity Updated.");
    } else {
      let count = 0;
      if (cartItems.length === 0) {
        dispatch(
          addItemsToCart(
            sku,
            id,
            qty,
            adds,
            addOnPrice,
            food_type,
            prepare_time,
            product_type,
            addComment,
            product,
            edit,
            prevsku
          )
        );
        if (startFunction instanceof Function) {
          // alert();
          startFunction();
        }
      } else {
        for (let i = 0; i < cartItems.length; i++) {
          if (sku === cartItems[i].sku) {
            count = 1;
          }
        }
        if (count === 0) {
          dispatch(
            addItemsToCart(
              sku,
              id,
              qty,
              adds,
              addOnPrice,
              food_type,
              prepare_time,
              product_type,
              addComment,
              product,
              edit,
              prevsku
            )
          );
          alert.success("Added the item to the cart");
        } else {
          alert.info("Item is Already in the cart");
        }
      }
      setAddOn([]);
      setAddOnPrice(0);
      setAddComment("");
      setQty(1);
      setProductPrice(product?.product_list?.price);
    }

    setIsOpen(false);
    //}
    // setAddOnSKU({});
  };
  const resetProduct = () => {
    setAddOn([]);
    setAddComment("");
    setQty(1);
    // setIsOpen(false);
    setProductPrice(product?.product_list?.price);
    setFirstRender(true);
    setAddOnPrice(0);
    setAlertAddOn([]);
  };

  const resetSingleAddOn = (title) => {
    console.log(title)
    setAddOn(prev => prev.filter(item => item.title !== title));
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed md:left-[-33.33%] inset-0 "
          style={{ zIndex: "67" }}
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black/20 backdrop-blur-[3px] w-full md:w-full" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className="inline-block w-full max-w-xs p-2 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
                key={product?.product_list?.product_list_id}
              >
                <Dialog.Title className="flex bg-primaryLightColor25 p-1 rounded-[10px]">
                  <img
                    src={product?.product_list?.card_img}
                    alt={product?.product_list?.product_name}
                    className="w-[60px] h-[60px] rounded-[10px] bg-slate-100"
                  />
                  <div className="ml-1 w-full">
                    <div className="flex justify-between gap-2">
                      <h6 className="font-bold text-primaryColor text-left leading-tight capitalize">
                        {product?.product_list?.product_name}
                      </h6>
                    </div>

                    <p className="font-bold text-left text-sm leading-tight text-mutedColor line-clamp-2">
                      {product?.product_list?.description}
                    </p>
                    <h6 className="text-primaryColor font-bold text-md">
                      &#8377;{product?.product_list?.price + product?.price}
                    </h6>
                  </div>
                  <RefreshIcon
                    className="w-8 h-8 cursor-pointer text-primaryColor"
                    onClick={resetProduct}
                  />
                </Dialog.Title>
                <div className="w-full">
                  <div
                    className="w-full max-w-md p-2 pt-0 mx-auto bg-white rounded-2xl mb-2 h-[300px] mt-2"
                    style={{ overflowY: "auto" }}
                  >
                    <Accordion allowZeroExpanded>
                      {sortedProduct !== null &&
                        sortedProduct?.product_list?.per_product_add_ons.map(
                          (add_on, i) => {
                            return <AddOnOption key={i} add_on={add_on} alertAddOn={alertAddOn} selectAddOn={selectAddOn} skus={skus} resetSingleAddOn={resetSingleAddOn} />
                          }
                        )}
                    </Accordion>

                    <div className="w-full mb-0 text-primaryColor font-bold">
                      Add Note:
                      <textarea
                        className="bg-primaryLightColorInput text-gray-700 rounded-[10px] w-full focus:outline-primaryColor placeholder:text-primaryColor/70 p-1"
                        placeholder="Additional note"
                        rows="2"
                        onChange={(e) => setAddComment(e.target.value)}
                        value={addComment}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="w-full max-w-md p-2 mx-auto bg-white flex justify-between items-center">
                  <div className="flex justify-center items-center">
                    <button
                      className="button-pimary-light p-2 bg-primaryLightColor25 text-sm text-primaryColor rounded-l-[10px]"
                      onClick={decQty}
                    >
                      <MinusIcon className="h-5 w-5" />
                    </button>
                    <button className="button-pimary-light p-2 bg-primaryLightColor25 text-sm text-primaryColor font-bold w-[30px] cursor-default">
                      {qty}
                    </button>
                    <button
                      className="button-pimary-light p-2 bg-primaryLightColor25 text-sm text-primaryColor rounded-r-[10px]"
                      onClick={incQty}
                    >
                      <PlusIcon className="h-5 w-5" />
                    </button>
                  </div>
                  <h6 className="text-primaryColor font-bold text-xl">
                    &#8377;{(productPrice + addOnPrice + product?.price) * qty}
                  </h6>
                </div>

                <div className="flex mt-2 gap-2">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-slate-500 bg-slate-100 border border-transparent rounded-[10px] hover:bg-slate-200 w-1/3 "
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  {product?.items_available > 0 ? (
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-white bg-primaryColor border border-transparent rounded-[10px] hover:bg-primaryDarkColor w-2/3"
                      onClick={addToCartHandler}
                    >
                      {edit ? "Update Cart" : "Add To Cart"}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-white bg-primaryColor/75 border border-transparent rounded-[10px] w-2/3 cursor-not-allowed"
                    >
                      Out Of Stock
                    </button>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

const AddOnOption = ({ add_on, alertAddOn, selectAddOn, skus, resetSingleAddOn }) => {
  const [filteredAddOn, setFilteredAddOn] = useState(add_on?.add_on?.add_on_options);
  useEffect(() => {
    setFilteredAddOn((prev) => {
      return [...prev].sort((op1, op2) => op1.order-op2.order)
    })
  }, [])
  return (
    <AccordionItem className="mb-2">
      <AccordionItemHeading className="sticky top-0 bg-white">
        <AccordionItemButton
          className={`flex justify-between items-center w-full px-4 py-2 text-sm text-left text-primaryColor bg-primaryLightColor25 rounded-[10px] hover:bg-primaryLightColor30 focus:outline-none uppercase font-bold ${alertAddOn.includes(
            add_on.add_on.title
          ) &&
            "bg-red-700 text-white hover:bg-red-800"
            }`}
        >
          <div className="w-full shrink flex justify-between items-center">
            <span>{add_on.add_on.title}</span><span className="lowercase text-red-500">{add_on.required === true ? ' *required' : <div className="w-fit">
                <GrPowerReset className="w-8 font-black aspect-square cursor-pointer text-primaryColor" onClick={(e) => {e.stopPropagation(); resetSingleAddOn(add_on.add_on.title)}} />
              </div> }</span>
          </div>
          {alertAddOn.includes(
            add_on.add_on.title
          ) && (
              <div className="w-fit">
                <ExclamationIcon className="h-[20px] fill-[yellow] stroke-[black]" />
              </div>
            )}
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="p-2">
        <ul className="flex flex-wrap gap-2">
          {filteredAddOn.map(
            (add_on_option, i) => {
              return (
                <li className="w-full" key={i}>
                  <input
                    className="hidden peer"
                    type={add_on.add_on.add_on_type}
                    value={JSON.stringify(
                      add_on_option
                    )}
                    name={add_on.add_on.title}
                    id={(
                      add_on_option.title +
                      add_on_option.price
                    ).replaceAll(" ", "")}
                    onChange={() =>
                      selectAddOn(
                        add_on_option,
                        add_on.add_on.title,
                        add_on.add_on.add_on_type
                      )
                    }
                    checked={skus.includes(
                      (
                        add_on_option.title +
                        add_on_option.price
                      ).replaceAll(" ", "")
                    )}
                  />
                  <label
                    className={`flex p-1 text-gray-500 bg-white  border-2 border-gray-300 rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-primaryColor peer-checked:ring-2  peer-checked:border-transparent peer-checked:bg-primaryColor peer-checked:text-white`}
                    htmlFor={(
                      add_on_option.title +
                      add_on_option.price
                    ).replaceAll(" ", "")}
                  >
                    {add_on_option.title}(+&#8377;
                    {add_on_option.price})
                  </label>
                </li>
              );
            }
          )}
        </ul>
      </AccordionItemPanel>
    </AccordionItem>
  );
}

export default Modal;
