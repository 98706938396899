import { Fragment, useCallback, useEffect, useRef, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { get } from "lodash";
import store from "../../store";
import orders from "../../api/orders";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";

const AddMoneyOtpModal = ({
  isOpenOtpModal,
  setIsOpenOtpModal,
  setIsMoneyOpen,
  setOtpResData,
  setIsCheckoutOpen,
}) => {
  // console.log(setIsMoneyOpen);
  const alert = useAlert();
  //  console.log(customerInfoRender)

  const [otp, setOtp] = useState("");

  let completeButtonRef = useRef(null);

  // get userinfo from redux store
  const userBranch2 = useSelector((state) => state.userBranch);
  const { userBranch } = userBranch2;

  //FUNCTION TO CLOSE THE MODAL
  function closeModal() {
    setIsOpenOtpModal(false);
    setIsMoneyOpen(true);
    if (setIsCheckoutOpen !== undefined) setIsCheckoutOpen(true);
  }

  // const mobileNO= customerInfoRender[0]?.mobile_no;

  // OTP VERIFICATION CALL
  const VerifyOtp = () => {
    const token = get(store.getState(), "userLogin.userInfo.token");
    const config = {
      headers: { "Content-Type": "application/json", token: token },
    };

    orders
      .post(
        "/verify_branch_otp",
        {
          OTP: otp,
          branch_id: userBranch?.branch_id,
          employee_id: userBranch?.employee_id
        },
        config
      )
      .then((res) => {
        setOtpResData(res.data);
        if (res.data === "Token does not match") {
          sessionStorage.clear();
          window.location.href = "/login";
        }

        if (res.data.status === "success") {
          closeModal();
          setOtp("");
          alert.success(res.data.msg);
          setIsMoneyOpen(true);
          return;
        }
        if (res.data.status === "failure") {
          alert.error(res.data.msg);
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const disableSubmit = otp.length < 4;


  return (
    <>
      <Transition appear show={isOpenOtpModal} as={Fragment}>
        <Dialog
          initialFocus={completeButtonRef}
          as="div"
          className="fixed inset-0 "
          style={{ zIndex: "70" }}
          onClose={closeModal}
        //   initialFocus={cancelButtonRef}
        >
          <div
            ref={completeButtonRef}
            className="min-h-screen px-4 text-center"
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-black/20 backdrop-blur-[3px] w-full " />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-xs p-4  overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title>
                  <div className="uppercase font-bold text-xl text-center text-gray-800">
                    OTP Verification
                  </div>
                </Dialog.Title>
                {/* <div className="h-screen bg-blue-500 py-20 px-3">
                  <div className="container mx-auto">
                    <div className="max-w-sm mx-auto md:max-w-lg">
                      <div className="w-full">
                        <div className="bg-white h-64 py-3 rounded text-center">
                          <h1 className="text-2xl font-bold">OTP Verification</h1>
                          <div className="flex flex-col mt-4">
                            <span>Enter the OTP you received at</span>
                            <span className="font-bold">+91 ******876</span>
                          </div>

                         

                          <div className="flex justify-center text-center mt-5">
                            <a className="flex items-center text-blue-700 hover:text-blue-900 cursor-pointer">
                              <span className="font-bold">Resend OTP</span>
                              <i className="bx bx-caret-right ml-1"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="flex flex-col m-auto text-center">
                  <span className="text-sm">Enter the Branch OTP you received at Login time</span>
                </div>
                {/* <div
                  id="otp"
                  className="flex flex-row justify-center text-center px-2 mt-5"
                >
                  <input
                    className="m-2 border h-10 w-10 text-center form-control rounded"
                    type="text"
                    id="first"
                    maxlength="1"
                  />
                  <input
                    className="m-2 border h-10 w-10 text-center form-control rounded"
                    type="text"
                    id="second"
                    maxlength="1"
                  />
                  <input
                    className="m-2 border h-10 w-10 text-center form-control rounded"
                    type="text"
                    id="third"
                    maxlength="1"
                  />
                  <input
                    className="m-2 border h-10 w-10 text-center form-control rounded"
                    type="text"
                    id="fourth"
                    maxlength="1"
                  />
                </div> */}
                <div className="outline flex outline-primaryLightColor30 relative input-primary my-2 m-auto p-1 w-full">
                  <input
                    type="number"
                    name="otp"
                    placeholder="Enter 4 Digit OTP"
                    className="block  px-2  text-md appearance-none focus:outline-none bg-transparent"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                </div>

                <div className="flex justify-center mt-2 gap-2">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-slate-500 bg-slate-100 border border-transparent rounded-[10px] hover:bg-slate-200 w-1/3 "
                    onClick={closeModal}
                  >
                    Close
                  </button>
                  <button
                    style={
                      disableSubmit
                        ? { background: "rgba(102, 77, 3, .3)" }
                        : { background: "#664d03" }
                    }
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-bold uppercase text-white bg-primaryLightColor border border-transparent rounded-[10px] hover:bg-slate-800 w-2/3 "
                    onClick={VerifyOtp}
                    disabled={disableSubmit}
                  >
                    Submit
                  </button>


                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AddMoneyOtpModal;
