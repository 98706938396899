import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { selectBranch } from "../../actions/userActions";
import { useAlert } from "react-alert";
import Logo from '../../assets/images/logo.png';

const LoginSelectBranch = () => {
  const navigate = useNavigate();
  const alert = useAlert();

  const [selectedBranch, setSelectedBranch] = useState([]);

  const redirect = "/branch-verify";

  //get userInfo from redux store
  const userLogin = useSelector((state) => state.userLogin);
  const userBranch2 = useSelector((state) => state.userBranch);
  const { userInfo } = userLogin;
  const { userBranch } = userBranch2;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  function handleSelectChange(event) {
    setSelectedBranch(userInfo.branches[+event.target.value]);
  }

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(selectBranch(selectedBranch.employee_id, selectedBranch.branch.branch_name, selectedBranch.branch.branch_id));
  };



  useEffect(() => {
    if (userBranch && userBranch?.status === "success") {
      navigate(redirect);
      //alert.success('OTP send successfully.');
    } else if (userBranch && userBranch?.status === "failure") {
      alert.error(userBranch?.status);
    }
  }, [userBranch, navigate, redirect, alert]);





  return (
    <div className="flex flex-wrap">
      <div className="w-full md:w-1/3">
        <div className="flex flex-col justify-center items-center h-screen">
          <img
            src={Logo}
            alt="HeeBee"
            className="w-[50px] mb-2 rounded-full"
          />
          <h1 className="text-2xl textColor font-bold mb-1">Welcome Back!</h1>
          <p className="font-bold mb-4 mutedColor">
            Select Branch to continue
          </p>
          <form className="w-10/12" autoComplete="off" onSubmit={submitHandler}>
            <div className="outline outline-primaryLightColor30 relative input-primary my-5 p-1 ">
              <select name="branch" className="block py-2 px-2 w-full text-lg appearance-none focus:outline-none bg-transparent" onChange={handleSelectChange} required>
                <option value="">Select Branch</option>
                {userInfo.branches.map((branch, i) => <option key={i} value={i}>{branch.branch.branch_name}</option>)}
              </select>
            </div>
            <div className="w-full mb-5">
              <button type="submit" className="button-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="h-screen w-full md:w-2/3 hidden md:block">
        <div className="authentication-bg d-none d-md-block">
          <div className="bg-overlay"></div>
        </div>
      </div>
    </div>
  );
};

export default LoginSelectBranch;
