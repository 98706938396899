import React, { useEffect } from "react";
import OrderList from "../home/OrderList";

const OrdersList = ({ ordersList, onOrderSelect }) => {
  const orders = ordersList.data;

  useEffect(() => {
    return onOrderSelect(orders[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (orders) {
    return (
      <>
        {orders?.map((order) => {
          return (
            <OrderList
              orderid={order.order_id}
              price={order.paid_price}
              order={order}
              key={order.order_id}
              onOrderSelect={onOrderSelect}
            />
          );
        })}
      </>
    );
  } else {
    return <div>Loading.....</div>;
  }
};

export default OrdersList;
