import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import orders from '../../api/orders';
import { get } from "lodash";
import store from "../../store";
import { applyPromoCode } from '../../actions/promoCodeAction';

const PromoCode = ({ item, amt, apply, setApply }) => {
  //console.log(item)
  const alert = useAlert();
  const dispatch = useDispatch();


  //const state = useSelector(state => state);
  // const applyPromoCode = useSelector((state) => state.applyPromoCode);

  // console.log(state.promoCode.promo_code_id)

  const applyHandler = () => {

    let data = {
      promo_code_id: item.promo_code_id,
      amount: amt
    };
    const token = get(store.getState(), "userLogin.userInfo.token");
    amt === '' ? alert.error('Please enter a Amount') :
      orders.post(`/apply_promo_code`, data, {
        headers: {
          token: token
        }
      })
        .then(res => {
          if (res.data.status === "success") {
            alert.success("Promo Code Applied Successfully");
            //dispatch(setPromoCode(res.data.data));
            dispatch(applyPromoCode(res.data.data))
            setApply(true)
          }
          else
            alert.error(res.data.msg)
        }
        )
        .catch(() => {
          alert.error("error occured2")
        });
  }
  //console.log(applyPromoCode.promo_code_id)



  return (

    <div className="w-full flex justify-between items-center bg-primaryColor/25 shadow p-2 mb-2 rounded-[10px] gap-2"><div><h6 className="font-bold uppercase text-primaryColor">{item.title}</h6><h6 className="font-bold Capitalize text-primaryColor text-xs text-left">Min Amount ₹{item.min_amount}</h6><h6 className="font-bold Capitalize text-primaryColor text-xs text-left">Extra Amount ₹{item.extra_amount}</h6></div>
      {
        // (amt >= item.min_amount) ?
        //(apply) ?
        //<button className="bg-primaryColor/50 text-white uppercase font-bold text-sm px-2 py-1 shadow-sm rounded-[10px] hover:bg-primaryColor/70 opacity-50" onClick={clearHandler}>Applied</button>
        <button className="bg-primaryColor/50 text-white uppercase font-bold text-sm px-2 py-1 shadow-sm rounded-[10px] hover:bg-primaryColor/70" onClick={applyHandler}>Apply</button>
        // : ''
      }
    </div>

  )
}

export default PromoCode